@import 'global.scss';

@mixin wide-screen {
  width: calc(100% + 40px);
  margin: 0 -20px;

  @include desktop-max {
    margin: 0;
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $gap-mobile;
  margin-bottom: $gap-mobile;
  width: 100%;
  @include tablet {
    gap: $gap-tablet;
    margin-bottom: $gap-tablet;
  }
  @include desktop-max {
    gap: $gap-desktop;
    margin-bottom: $gap-desktop;
  }
}

.carouselWrapper {
  @include wide-screen;
  min-height: 272px;

  @include tablet {
    min-height: 209px;
  }

  @include desktop {
    min-height: 414px;
  }
}

.tariffs {
  @include wide-screen;
}

.tariffCard {
  width: 100%;
}

.banners {
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  gap: $gap-mobile;

  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 395px 322px 1fr;
    gap: $gap-tablet $gap-horizontal;
    place-items: stretch stretch;
    grid-template-areas:
      'Replenishment BeautifulNumbers'
      'VolnaSale Esim'
      'HomeInternet HomeInternet';

    &__beautiful_numbers {
      grid-area: BeautifulNumbers;
    }
    &__volna_sale {
      grid-area: VolnaSale;
    }
    &__esim {
      grid-area: Esim;
    }
    &__replenishment {
      grid-area: Replenishment;
    }
    &__home_internet {
      grid-area: HomeInternet;
    }
  }

  @include desktop-max {
    grid-template-columns: 50% auto 310px;
    grid-template-rows: 322px 356px;
    grid-template-areas:
      'BeautifulNumbers VolnaSale Esim'
      'Replenishment HomeInternet HomeInternet';
    place-items: stretch stretch;
    gap: $gap-desktop $gap-horizontal;

    &__volna_sale {
      margin-left: -120px;
    }
  }
}

.news {
  @include wide-screen;
}

.resultModalTitle {
  text-wrap: nowrap;
}
