@import 'media-queries';

.card {
  padding: 24px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 190, 0.15);
  background-image: url('/assets/img/eSIM.png');
  background-repeat: no-repeat;
  background-size: 140px;
  background-position: 90% 25%;
  gap: inherit;
  transition: all 0.2s ease-in-out;

  @include tablet {
    height: auto;
    background-position: left 140px bottom;
    background-size: 300px;
  }

  &:hover {
    border-radius: 24px 24px 24px 0;
    box-shadow: 0px 25px 45px 0px rgba(0, 0, 190, 0.2);
    transition: all 0.2s ease-in-out;
  }
}

.headerInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include only-mobile {
    margin-top: 30px;
  }
}

.header {
  display: block;
  font-family: 'TT Norms Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;

  @include desktop-max {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.32px;
  }
}

.info {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-self: flex-start;
}

.button {
  &__root {
    margin-top: auto;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    background-color: $color-primary-500;
    color: $color-base-0;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    outline: none;

    &:hover {
      background-color: $color-primary-400;
    }

    &:active {
      background-color: $color-primary-500;
    }

    &[data-disabled] {
      background-color: $color-primary-200;
      color: $color-base-500;
    }

    @include tablet {
      width: 151px;
    }

    @include desktop-max {
      height: 40px;
    }
  }
}