@import '@/styles/global.scss';

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 28px auto;
  gap: 16px;
  height: 100%;
  padding-top: 16px;
  width: 100%;

  @include desktop-max {
    grid-template-rows: 28px 65px auto;
  }
}

.light {
  position: relative;

  @include tablet {
    grid-template-rows: 48px auto;
    gap: $gap-mobile;
  }
  @include desktop-max {
    padding-top: 24px;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 1fr;
    gap: 16px;
  }
}

.payment {
  display: flex;
  gap: 12px;
  align-items: center;
}

.light .payment {
  @include tablet {
    position: absolute;
    right: 0;
    top: -34px;
  }
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: $corner-radius-small;

  &__phone,
  &__currency {
    width: 100%;
  }

  @include desktop-max {
    grid-template-columns: minmax(233px, 1fr) minmax(168px, 1fr) minmax(auto, 1fr);
  }
}

.light .form {
  grid-template-columns: 1fr;

  @include tablet {
    grid-template-columns: minmax(233px, 1fr) minmax(168px, 1fr) minmax(auto, 1fr);
    gap: $corner-radius-small;
  }
}

.segment {
  &__root {
    height: 40px;
    padding: 2px;
    border-radius: 8px;
    background-color: $color-base-200;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include desktop-max {
      height: 48px;
    }
  }

  &__control {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop-max {
      height: 44px;
    }
  }

  &__label {
    height: 36px;
    padding: 12px;
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    @include label-l3;
    color: $color-black;

    @include desktop-max {
      height: 44px;
    }
  }

  &__indicator {
    height: 36px;
    top: 4px;
    left: 4px;
    border-radius: 8px;
    box-shadow: none;

    @include desktop-max {
      height: 44px;
    }
  }
}

.light .segment {
  @include tablet {
    &__root {
      height: 48px;
    }
    &__control {
      height: 48px;
    }
    &__label {
      height: 48px;
    }
    &__indicator {
      height: 48px;
    }
  }
}

.button {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: auto;

  &__root {
    height: 48px;
    background-color: $color-magenta-100;
    width: 100%;
    border-radius: 12px;
    border: none;
    outline: none;
    @include label-l4-m;

    &:hover {
      background-color: $color-magenta-0;
    }
    &:active {
      background-color: $color-magenta-200;
    }
    &[data-disabled] {
      background-color: $color-base-200;
      color: $color-base-500;
    }
    @include desktop-max {
      height: 40px;
    }
  }

  @include desktop-max {
    grid-template-columns: minmax(233px, 1fr) minmax(168px, 1fr) minmax(auto, 1fr);
    gap: 16px;
  }
}

.light .button {
  @include tablet {
    grid-template-columns: minmax(233px, 1fr) minmax(168px, 1fr) minmax(auto, 1fr);
    gap: 16px;
  }
  @include desktop-max {
    grid-template-columns: 1fr;

    &__root {
      height: 48px;
    }
  }
}
