@import 'global.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.loaderWrapper {
  position: relative;
}

.swiper {
  height: 524px;
  width: 100%;
  display: flex;
  flex-direction: column;

  :global(.swiper-wrapper) {
    align-items: center;
    height: fit-content;
  }
  :global(.swiper-slide) {
    width: 310px !important;

    &:first-of-type {
      margin-left: 20px;
      @include desktop-max {
        margin-left: 0;
      }
    }
    &:last-of-type {
      margin-right: 20px;
      @include desktop-max {
        margin-right: 0;
      }
    }
  }
  :global(.swiper-button-next) {
    top: 187px !important;
  }
  :global(.swiper-button-prev) {
    top: 187px !important;
  }
}

.more {
  height: 480px;

  &__wrapper {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-base-100;
    width: 310px;
    height: 360px;
    border-radius: 24px;
    flex-direction: column;
    gap: 20px;

    &:hover {
      background-color: $color-primary-100;
    }
  }

  &__icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $color-base-0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    @include short-text-t4;
  }
}

.loading {
  position: relative;
  height: 400px;
  z-index: 1;
}
