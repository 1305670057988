@import 'global.scss';

.swiper {
  @include desktop-max {
    border-radius: 24px;
  }

  :global(.swiper-slide) {
    width: 100%;
    height: calc(100vw * 0.73);

    @include tablet {
      height: calc(100vw * 0.32);
    }

    @include desktop-max {
      height: 414px;
    }
  }

  :global(.swiper-pagination) {
    position: absolute !important;
    left: 0;
    bottom: 0;
    top: initial;
    width: 100%;
  }

  :global(.swiper-pagination-bullet) {
    background-color: $color-base-0 !important;
  }
}

.skeleton {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #dee2e6;
  width: 100%;
  animation: skeletonAnimation 1500ms linear infinite;
  height: 100%;
}

@keyframes skeletonAnimation {
  from,
  to {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vw * 0.73);

  @include tablet {
    height: calc(100vw * 0.32);
  }

  @include desktop-max {
    height: 414px;
  }
}

.image {
  background-color: $color-base-0;
  width: 100%;
  height: 100%;

  &__video {
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: cover;

    ::after {
      border-radius: $corner-radius-large;
    }
  }
}
