@import 'global.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;

  @include desktop-max {
    margin: 0;
  }
}
.link {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  span {
    @include heading-h3;
    color: $color-neutral-gray;
  }
  svg {
    padding-top: 4px;
  }
}
