@import 'global.scss';

.container {
  border-radius: $corner-radius-large;
  background: linear-gradient(90deg, #580eb1 0%, #6701a9 100%);
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 190, 0.15);
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: $corner-radius-small;
  flex: 1 0 0;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-radius: $corner-radius-large $corner-radius-large $corner-radius-large 0px;
    box-shadow: 0px 25px 45px 0px rgba(0, 0, 190, 0.2);
    transition: all 0.2s ease-in-out;
  }
}
.light {
  background: $color-base-100;
}
.link {
  display: flex;
  gap: 4px;
  align-items: baseline;
  height: 28px;

  @include desktop-max {
    height: 36px;
  }

  &__text {
    display: block;
    color: $color-base-0;
    @include heading-h4;
    @include desktop-max {
      @include heading-h3;
    }
  }

  &__chevron {
    padding-top: 5px;
  }
}

.tabs {
  &__root {
    width: 100%;
    height: 100%;
  }

  &__tabsList {
    width: fit-content;
    height: 34px;
    border: none;
    flex-wrap: nowrap;
    max-width: 100%;
  }

  &__tab {
    color: $color-base-400;
    text-align: center;
    @include label-l4;
    border-color: $color-base-400;
    border-width: 1px;

    &[data-active] {
      color: $color-base-0;
      border-color: $color-base-0;
      border-width: 3px;
      &:hover {
        border-color: $color-base-0;
        color: $color-base-0;
      }
    }
    &:active {
      border-color: $color-base-0;
    }
    &:hover {
      background: transparent;
    }

    @include only-mobile {
      padding: 10px 12px;
    }
  }

  &__panel {
    height: calc(100% - 34px);
  }
}

.light .tabs__tab {
  color: $color-base-700;
  border-color: $color-base-400;

  &[data-active] {
    color: $color-base-900;
    border-color: $color-primary-500;
    &:hover {
      color: $color-base-900;
      border-color: $color-primary-500;
    }
  }
  &:active {
    border-color: $color-primary-600;
  }
}
