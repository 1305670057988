@import '@/styles/global.scss';

.container {
  display: flex;
  width: 100%;
  padding: 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: $gap-mobile;
  border-radius: $corner-radius-large;
  background: $gradient-blue;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 182, 0.15);
  transition: all 0.2s ease-in-out;

  &:hover {
    border-radius: $corner-radius-large $corner-radius-large $corner-radius-large 0px;
    box-shadow: 0px 25px 45px 0px rgba(0, 0, 190, 0.2);
    transition: all 0.2s ease-in-out;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $corner-radius-large;
  align-self: stretch;
}

.link {
  display: flex;
  gap: 4px;
  align-items: baseline;
  height: 28px;

  @include desktop-max {
    height: 36px;
  }

  &__text {
    display: block;
    @include heading-h4;
    color: $color-base-0;

    @include tablet {
      @include heading-h3;
    }
  }

  &__chevron {
    padding-top: 5px;
  }
}

.input {
  &__root {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__label {
    color: $color-base-400;
    @include label-l5;
  }

  &__input {
    height: 48px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 24px;
    border-radius: 12px;
    border: 1px solid $color-base-200;
    background: transparent;
    outline: none;
    @include label-l4-m;
    color: $color-base-0;

    &:hover,
    &:active,
    &:focus {
      border-color: $color-base-200;
      outline: none;
    }

    &::placeholder {
      color: $color-base-400;
    }
  }

  &__right_section {
    width: auto;
    right: 2px;

    @include only-mobile {
      display: none;
    }
  }
}

.description {
  color: $color-base-0;
  @include short-text-t4;
}

.footer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 12px;
  margin-top: auto;
  width: 100%;

  @include tablet {
    align-self: stretch;
    flex-direction: row;
  }
}

.button {
  &__root {
    height: 48px;
    width: 100%;
    border-radius: 12px;
    background-color: $color-base-0;
    color: $color-black;
    @include label-l4-m;
    outline: none;

    &:hover {
      background-color: $color-base-200;
    }

    &:active {
      background-color: $color-base-300;
    }

    &[data-disabled] {
      background-color: $color-base-200;
      color: $color-base-500;
    }

    @include tablet {
      width: 233px;
    }

    @include desktop-max {
      height: 40px;
    }
  }
}

.price {
  color: $color-base-0;
  @include label-l1-m;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  @include tablet {
    padding: 0px $corner-radius-small;
  }
}

.loading {
  :global(.mantine-LoadingOverlay-root) {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 12px;
  }
}