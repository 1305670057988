@import 'global.scss';

.wrapper {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 310px;

  &:hover {
    .text,
    .date,
    .more,
    .more__arrow path {
      color: $color-magenta-100;
      stroke: $color-magenta-100;
    }
  }
}

.img {
  height: 360px;
  object-fit: cover;
  width: 100%;
  border-radius: $corner-radius-large;
  //   filter: grayscale(100%);
}

.imgWrapper {
  border-radius: 24px;
  overflow: hidden;
}
.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 108px;
}

.text {
  @include short-text-t4;
}

.date {
  @include label-l5;
  color: $color-base-700;
}

.more {
  margin-top: auto;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;
  span {
    @include label-l4;
  }
}

.tag {
  position: absolute;
  top: 16px;
  right: 16px;
  color: $color-base-0;
  background-color: $color-magenta-100;
  border-radius: 12px;
  padding: 6px 12px;
  @include label-l6;
}

// .cardWrapper:hover .cardImg {
//   filter: grayscale(0);
// }
