@import 'global.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: $corner-radius-large;
  padding: 0 24px 24px 24px;

  @include tablet {
    padding: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  @include heading-h4;
  color: $color-black;
  width: 320px;

  @include tablet {
    width: 100%;
  }

  @include desktop-max {
    @include heading-h2;
    width: 500px;
  }
}

.info {
  p {
    color: $color-black;
    @include label-l3;
    line-height: 24px;
  }
}

.button {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  padding: 17px 32px;
  background-color: $color-magenta-100;
  color: $color-base-0;
  @include label-l4-m;
  outline: none;

  &:hover {
    background-color: $color-magenta-100;
  }
  &:active {
    background-color: $color-magenta-100;
  }
  &:focus,
  :focus-visible {
    outline: none;
  }
  &[data-disabled] {
    background-color: $color-primary-200;
    color: $color-base-500;
  }
  @include tablet {
    height: 40px;
    padding: 13px 24px;
  }
  @include desktop-max {
    width: 286px;
  }
  :global(.mantine-Button-label) {
    height: 48px;
  }
}

.bottomsheet {
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  min-width: 478px;
  max-width: 478px;
  @include desktop-max {
    min-width: 640px;
    max-width: 640px;
  }
}

.closeButton {
  :global(.mantine-CloseButton-root) {
    outline: none !important;
    color: $color-base-800;
    top: 6px;
    right: 6px;
    svg {
      width: 22px !important;
      height: 22px !important;
    }
  }
}

.orderTitle {
  display: flex;
  justify-content: space-between;
  @include only-mobile {
    flex-direction: column;
    gap: 20px;
  }
  @include desktop {
    width: 500px;
  }
}

.phoneNumber,
.numberOrder {
  @include label-l3;
  font-weight: 700;
  line-height: 24px;
}

.titleInfo {
  @include label-l3;
  line-height: 24px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  gap: 12px;
  @include only-mobile {
    flex-direction: column;
  }
}

.btn,
.btnCopy {
  @include label-l4;
  width: 290px;
  height: 40px;
  border: none;
  background-color: $color-magenta-100;
  color: $color-base-0;
  border-radius: 12px;
  font-weight: 500;
  order: 1;
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:active {
    transform: translateY(2px);
  }

  @include only-mobile {
    width: 100%;
    order: 2;
  }
}

.btn {
  &:hover {
    background-color: $color-magenta-200;
  }
}

.btnCopy {
  order: 2;
  background-color: $color-base-100;
  color: $color-black;
  cursor: pointer;

  &:hover {
    background-color: $color-base-200;
  }

  &:active {
    background-color: $color-base-300;
  }

  @include only-mobile {
    order: 1;
  }
}
